import * as types from '../types';

export const getAllModels = (filters, page, count) => ({
  type: types.GET_ALL_MODELS_REQUEST,
  payload: {
    filters,
    page,
    count
  },
});

export const saveSelectedModels = (values, modelData, authEmail, fileData, onSuccess) => ({
  type: types.SAVE_SELECTED_MODELS_REQUEST,
  payload: {
    values,
    modelData,
    authEmail,
  },
  meta: {
    fileData,
    onSuccess,
  },
});

export const editModel = (values, onSuccess) => ({
  type: types.EDIT_MODEL_REQUEST,
  payload: {
    values,
  },
  meta: {
    onSuccess,
  },
});

export const deleteModel = (values, onSuccess) => ({
  type: types.DELETE_MODEL_REQUEST,
  payload: {
    values,
  },
  meta: {
    onSuccess,
  },
});

export const getActivesList = onSuccess => ({
  type: types.GET_ACTIVES_LIST_REQUEST,
  meta: {
    onSuccess,
  },
});

export const showAvailableModels = (active1Data, active2Data, active3Data, onSuccess) => ({
  type: types.SHOW_AVAILABLE_MODELS_REQUEST,
  payload: {
    active1Data,
    active2Data,
    active3Data,
  },
  meta: {
    onSuccess,
  },
});

export const showAvailableModels1 = (active1Data, active2Data, active3Data, onSuccess) => ({
  type: types.SHOW_AVAILABLE_MODELS_1_REQUEST,
  payload: {
    active1Data,
    active2Data,
    active3Data,
  },
  meta: {
    onSuccess,
  },
});

export const getPrediction = (values, modelData, onSuccess) => ({
  type: types.PREDICT_REQUEST,
  payload: {
    values,
    modelData,
  },
  meta: {
    onSuccess,
  },
});

export const getSuggestiveFormulation = (target, targetValue, models, onSuccess) => ({
  type: types.GET_SUGGESTIVE_FORMULATIONS_RESULT_REQUEST,
  payload: {
    target,
    targetValue,
    models,
  },
  meta: {
    onSuccess,
  },
});

export const showAllModels = (onSuccess) => ({
  type: types.SHOW_ALL_MODELS_REQUEST,
  meta: {
    onSuccess,
  },
});

export const getModelDataFromId = (modelId, onSuccess) => ({
  type: types.GET_MODEL_DATA_FROM_ID_REQUEST,
  payload: {
    modelId,
  },
  meta: {
    onSuccess,
  },
});

export const resetPredictionResult = () => ({
  type: types.RESET_PREDICTION_RESULT,
});
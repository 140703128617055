import * as React from 'react';
import { ChartComponent, LineSeries, SeriesCollectionDirective, SeriesDirective, Inject, Legend, Category, DataLabel, Tooltip } from '@syncfusion/ej2-react-charts';
import InfoIcon from '@mui/icons-material/Info';
import IconButton from '@mui/material/IconButton';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

// const tempData = [{ x: -100, y: 0 }, { x: 100, y: 0 }];
const LinePlot = ({ graphData, smaller }) => {
  // const res1 = graphData.reduce(function (prev, current) {
  //   return (prev.x < current.x) ? prev : current
  // });
  // const res2 = graphData.reduce(function (prev, current) {
  //   return (prev.x > current.x) ? prev : current
  // });
  // const tempData = [{ x: res1.x, y: 0 }, { x: res2.x, y: 0 }];
  return (
    <>
      <ChartComponent
        id='charts'
        tooltip={{ enable: true }}
        style={{ textAlign: 'center', width: '100%', height: smaller ? '100%' : '100%' }}
        marker={{ dataLabel: { visible: true } }}
        legendSettings={{ visible: true }}
        isTransposed={true}
        primaryYAxis={{
          title: 'Minutes',
        }}
        primaryXAxis={{
          //   // minimum: 100,
          //   // maximum: 220,
          //   // majorGridLines: { width: 0 },
          //   // edgeLabelPlacement: 'Shift',
          valueType: 'Category',
        }}
      >
        <Inject services={[LineSeries, DataLabel, Tooltip, Category]} />
        <SeriesCollectionDirective>
          <SeriesDirective
            dataSource={graphData}
            xName='y'
            yName='x'
            type='Line'
            marker={{ visible: true, width: 12, height: 12, shape: 'Circle', color: '#0000FF' }}
          />
        </SeriesCollectionDirective>
      </ChartComponent>
    </>
  );
};

export default LinePlot;

// Auth
// login firebase auth
export const LOGIN_REQUEST = 'LOGIN_REQUEST';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILURE = 'LOGIN_FAILURE';

// logout firebase auth
export const LOGOUT_REQUEST = 'LOGOUT_REQUEST';
export const LOGOUT_SUCCESS = 'LOGOUT_SUCCESS';
export const LOGOUT_FAILURE = 'LOGOUT_FAILURE';

export const UPLOAD_FILE_REQUEST = 'UPLOAD_FILE_REQUEST';
export const UPLOAD_FILE_SUCCESS = 'UPLOAD_FILE_SUCCESS';
export const UPLOAD_FILE_FAILURE = 'UPLOAD_FILE_FAILURE';

export const CHECK_USER_SESSION = 'CHECK_USER_SESSION';

export const SAVE_SELECTED_MODELS_REQUEST = 'SAVE_SELECTED_MODELS_REQUEST';
export const SAVE_SELECTED_MODELS_SUCCESS = 'SAVE_SELECTED_MODELS_SUCCESS';
export const SAVE_SELECTED_MODELS_FAILURE = 'SAVE_SELECTED_MODELS_FAILURE';

export const GET_SUGGESTIVE_FORMULATIONS_RESULT_REQUEST = 'GET_SUGGESTIVE_FORMULATIONS_RESULT_REQUEST';
export const GET_SUGGESTIVE_FORMULATIONS_RESULT_SUCCESS = 'GET_SUGGESTIVE_FORMULATIONS_RESULT_SUCCESS';
export const GET_SUGGESTIVE_FORMULATIONS_RESULT_FAILURE = 'GET_SUGGESTIVE_FORMULATIONS_RESULT_FAILURE';

export const SHOW_AVAILABLE_MODELS_REQUEST = 'SHOW_AVAILABLE_MODELS_REQUEST';
export const SHOW_AVAILABLE_MODELS_SUCCESS = 'SHOW_AVAILABLE_MODELS_SUCCESS';
export const SHOW_AVAILABLE_MODELS_FAILURE = 'SHOW_AVAILABLE_MODELS_FAILURE';

export const SHOW_AVAILABLE_MODELS_1_REQUEST = 'SHOW_AVAILABLE_MODELS_1_REQUEST';
export const SHOW_AVAILABLE_MODELS_1_SUCCESS = 'SHOW_AVAILABLE_MODELS_1_SUCCESS';
export const SHOW_AVAILABLE_MODELS_1_FAILURE = 'SHOW_AVAILABLE_MODELS_1_FAILURE';

export const SHOW_ALL_MODELS_REQUEST = 'SHOW_ALL_MODELS_REQUEST';
export const SHOW_ALL_MODELS_SUCCESS = 'SHOW_ALL_MODELS_SUCCESS';
export const SHOW_ALL_MODELS_FAILURE = 'SHOW_ALL_MODELS_FAILURE';

export const GET_MODEL_DATA_FROM_ID_REQUEST = 'GET_MODEL_DATA_FROM_ID_REQUEST';
export const GET_MODEL_DATA_FROM_ID_SUCCESS = 'GET_MODEL_DATA_FROM_ID_SUCCESS';
export const GET_MODEL_DATA_FROM_ID_FAILURE = 'GET_MODEL_DATA_FROM_ID_FAILURE';

export const EDIT_MODEL_REQUEST = 'EDIT_MODEL_REQUEST';
export const EDIT_MODEL_SUCCESS = 'EDIT_MODEL_SUCCESS';
export const EDIT_MODEL_FAILURE = 'EDIT_MODEL_FAILURE';

export const DELETE_MODEL_REQUEST = 'DELETE_MODEL_REQUEST';
export const DELETE_MODEL_SUCCESS = 'DELETE_MODEL_SUCCESS';
export const DELETE_MODEL_FAILURE = 'DELETE_MODEL_FAILURE';

export const GET_ALL_MODELS_REQUEST = 'GET_ALL_MODELS_REQUEST';
export const GET_ALL_MODELS_SUCCESS = 'GET_ALL_MODELS_SUCCESS';
export const GET_ALL_MODELS_FAILURE = 'GET_ALL_MODELS_FAILURE';

export const GET_ACTIVES_LIST_REQUEST = 'GET_ACTIVES_LIST_REQUEST';
export const GET_ACTIVES_LIST_SUCCESS = 'GET_ACTIVES_LIST_SUCCESS';
export const GET_ACTIVES_LIST_FAILURE = 'GET_ACTIVES_LIST_FAILURE';

export const PREDICT_REQUEST = 'PREDICT_REQUEST';
export const PREDICT_SUCCESS = 'PREDICT_SUCCESS';
export const PREDICT_FAILURE = 'PREDICT_FAILURE';

export const SAVE_MODEL_DATA_FOR_PREDICTION_REQUEST = 'SAVE_MODEL_DATA_FOR_PREDICTION_REQUEST';

export const UPDATE_USER_INFO = 'UPDATE_USER_INFO';
export const CHANGE_THEME = 'CHANGE_THEME';
export const TOGGLE_SIDENAV = 'TOGGLE_SIDENAV';

export const DOWNLOAD_FILE_REQUEST = 'DOWNLOAD_FILE_REQUEST';
export const DOWNLOAD_FILE_SUCCESS = 'DOWNLOAD_FILE_SUCCESS';
export const DOWNLOAD_FILE_FAILURE = 'DOWNLOAD_FILE_FAILURE';

// Exchange Rate Master Data
export const ADD_EXCHANGE_RATE_REQUEST = 'ADD_EXCHANGE_RATE_REQUEST';
export const ADD_EXCHANGE_RATE_SUCCESS = 'ADD_EXCHANGE_RATE_SUCCESS';
export const ADD_EXCHANGE_RATE_FAILURE = 'ADD_EXCHANGE_RATE_FAILURE';

export const EDIT_EXCHANGE_RATE_REQUEST = 'EDIT_EXCHANGE_RATE_REQUEST';
export const EDIT_EXCHANGE_RATE_SUCCESS = 'EDIT_EXCHANGE_RATE_SUCCESS';
export const EDIT_EXCHANGE_RATE_FAILURE = 'EDIT_EXCHANGE_RATE_FAILURE';

export const DELETE_EXCHANGE_RATE_REQUEST = 'DELETE_EXCHANGE_RATE_REQUEST';
export const DELETE_EXCHANGE_RATE_SUCCESS = 'DELETE_EXCHANGE_RATE_SUCCESS';
export const DELETE_EXCHANGE_RATE_FAILURE = 'DELETE_EXCHANGE_RATE_FAILURE';

export const GET_EXCHANGE_RATES_REQUEST = 'GET_EXCHANGE_RATES_REQUEST';
export const GET_EXCHANGE_RATES_SUCCESS = 'GET_EXCHANGE_RATES_SUCCESS';
export const GET_EXCHANGE_RATES_FAILURE = 'GET_EXCHANGE_RATES_FAILURE';

export const GET_MASTER_DATA_REQUEST = 'GET_MASTER_DATA_REQUEST';
export const GET_MASTER_DATA_SUCCESS = 'GET_MASTER_DATA_SUCCESS';
export const GET_MASTER_DATA_FAILURE = 'GET_MASTER_DATA_FAILURE';

export const ADD_MASTER_DATA_REQUEST = 'ADD_MASTER_DATA_REQUEST';
export const ADD_MASTER_DATA_SUCCESS = 'ADD_MASTER_DATA_SUCCESS';
export const ADD_MASTER_DATA_FAILURE = 'ADD_MASTER_DATA_FAILURE';

export const EDIT_MASTER_DATA_REQUEST = 'EDIT_MASTER_DATA_REQUEST';
export const EDIT_MASTER_DATA_SUCCESS = 'EDIT_MASTER_DATA_SUCCESS';
export const EDIT_MASTER_DATA_FAILURE = 'EDIT_MASTER_DATA_FAILURE';

export const DELETE_MASTER_DATA_REQUEST = 'DELETE_MASTER_DATA_REQUEST';
export const DELETE_MASTER_DATA_SUCCESS = 'DELETE_MASTER_DATA_SUCCESS';
export const DELETE_MASTER_DATA_FAILURE = 'DELETE_MASTER_DATA_FAILURE';

export const ADD_PROJECT_REQUEST = 'ADD_PROJECT_REQUEST';
export const ADD_PROJECT_SUCCESS = 'ADD_PROJECT_SUCCESS';
export const ADD_PROJECT_FAILURE = 'ADD_PROJECT_FAILURE';

export const EDIT_PROJECT_REQUEST = 'EDIT_PROJECT_REQUEST';
export const EDIT_PROJECT_SUCCESS = 'EDIT_PROJECT_SUCCESS';
export const EDIT_PROJECT_FAILURE = 'EDIT_PROJECT_FAILURE';

export const DELETE_PROJECT_REQUEST = 'DELETE_PROJECT_REQUEST';
export const DELETE_PROJECT_SUCCESS = 'DELETE_PROJECT_SUCCESS';
export const DELETE_PROJECT_FAILURE = 'DELETE_PROJECT_FAILURE';

export const GET_PROJECTS_REQUEST = 'GET_PROJECTS_REQUEST';
export const GET_PROJECTS_SUCCESS = 'GET_PROJECTS_SUCCESS';
export const GET_PROJECTS_FAILURE = 'GET_PROJECTS_FAILURE';

export const ADD_REGULATORY_LIMIT_REQUEST = 'ADD_REGULATORY_LIMIT_REQUEST';
export const ADD_REGULATORY_LIMIT_SUCCESS = 'ADD_REGULATORY_LIMIT_SUCCESS';
export const ADD_REGULATORY_LIMIT_FAILURE = 'ADD_REGULATORY_LIMIT_FAILURE';

export const EDIT_REGULATORY_LIMIT_REQUEST = 'EDIT_REGULATORY_LIMIT_REQUEST';
export const EDIT_REGULATORY_LIMIT_SUCCESS = 'EDIT_REGULATORY_LIMIT_SUCCESS';
export const EDIT_REGULATORY_LIMIT_FAILURE = 'EDIT_REGULATORY_LIMIT_FAILURE';

export const DELETE_REGULATORY_LIMIT_REQUEST = 'DELETE_REGULATORY_LIMIT_REQUEST';
export const DELETE_REGULATORY_LIMIT_SUCCESS = 'DELETE_REGULATORY_LIMIT_SUCCESS';
export const DELETE_REGULATORY_LIMIT_FAILURE = 'DELETE_REGULATORY_LIMIT_FAILURE';

export const GET_REGULATORY_LIMITS_REQUEST = 'GET_REGULATORY_LIMITS_REQUEST';
export const GET_REGULATORY_LIMITS_SUCCESS = 'GET_REGULATORY_LIMITS_SUCCESS';
export const GET_REGULATORY_LIMITS_FAILURE = 'GET_REGULATORY_LIMITS_FAILURE';

export const GET_USER_ACCESS_DATA_REQUEST = 'GET_USER_ACCESS_DATA_REQUEST';
export const GET_USER_ACCESS_DATA_SUCCESS = 'GET_USER_ACCESS_DATA_SUCCESS';
export const GET_USER_ACCESS_DATA_FAILURE = 'GET_USER_ACCESS_DATA_FAILURE';

export const ADD_USER_REQUEST = 'ADD_USER_REQUEST';
export const ADD_USER_SUCCESS = 'ADD_USER_SUCCESS';
export const ADD_USER_FAILURE = 'ADD_USER_FAILURE';

export const EDIT_USER_REQUEST = 'EDIT_USER_REQUEST';
export const EDIT_USER_SUCCESS = 'EDIT_USER_SUCCESS';
export const EDIT_USER_FAILURE = 'EDIT_USER_FAILURE';

export const DELETE_USER_REQUEST = 'DELETE_USER_REQUEST';
export const DELETE_USER_SUCCESS = 'DELETE_USER_SUCCESS';
export const DELETE_USER_FAILURE = 'DELETE_USER_FAILURE';

export const GET_USERS_REQUEST = 'GET_USERS_REQUEST';
export const GET_USERS_SUCCESS = 'GET_USERS_SUCCESS';
export const GET_USERS_FAILURE = 'GET_USERS_FAILURE';

export const RESET_PREDICTION_RESULT = 'RESET_PREDICTION_RESULT';